// Tax year from which the system generated tax filings started
export const taxFilingsStartYear = 2024;

export type TaxFiling = {
	id: number;
	reg_date: string;
	individnr: string;
	indkomstaar: number;
	indberetningstype: number | null;
	rettekode: 0 | 1 | 2;
	manual_filename: string | null;
	filename: string | null;
	entries: number;
	name: string;
	description: string;
	first_status_id: number | null;
	first_status_timestamp_ts: string | null;
	first_status_code: number | null;
	latest_status_id: number | null;
	latest_status_timestamp_ts: string | null;
	latest_status_code: number | null;
};

export type TaxFilingStatus = {
	id: number;
	filing_id: number;
	reg_date: string;
	status_file_code: 0 | 1 | 2 | 3;
	status_file: string;
	status_filename: string;
	filename: string;
	ftptransaktionsid: string;
	skattransactionid: string;
	timestamp: string;
	timestamp_ts: string;
	status_code: string;
	service: string;
	response_filename: string;
	error_code: string;
	error_message: string;
	error_resolution: string;
	advis_filename: string | null;
	fejl_filename: string | null;
};

export const individnrs: { individnr: string; description: string }[] = [
	{
		individnr: "IFPA2235",
		description: "Udbytte og beholdning (IFPA2235)",
	},
	{
		individnr: "IFPA2237",
		description: "Køb og salg (IFPA2237)",
	},
];

export const indberetningstyper: {
	indberetningstype: number;
	description: string;
}[] = [
	{
		indberetningstype: 1,
		description: "Indberetning om udbytte, udlodning/geninvestering (1)",
	},
	{
		indberetningstype: 2,
		description: "Indberetning om beholdninger (2)",
	},
];

export const rettekoder: { rettekode: number; description: string }[] = [
	{ rettekode: 0, description: "Ordinær indberetning (0)" },
	{
		rettekode: 1,
		description: "Ændring til en tidligere godkendt indberetning (1)",
	},
	{ rettekode: 2, description: "Genindberetning af afvist indberetning (2)" },
];

export function indberetningstypeDescription(indberetningstype: number | null) {
	return (
		indberetningstyper.find((t) => t.indberetningstype === indberetningstype)
			?.description ?? "Ikke oplyst"
	);
}

export function rettekodeDescription(rettekode: number | null) {
	return rettekoder.find((t) => t.rettekode === rettekode)?.description;
}

export function statusCodeDescription(status: number | null) {
	switch (status) {
		case 0:
			return "Fejl (0)";
		case 1:
			return "Modtaget (1)";
		case 2:
			return "Accepteret (2)";
		case 3:
			return "Behandlet (3)";
		default:
			return "";
	}
}
