import { LitElement, html } from "lit";
import { BASE_PATH } from "./env.js";
import mainStyle from "./styles/main.js";

const ROOT = BASE_PATH === "/" ? "" : BASE_PATH;

export default class MainElement extends LitElement {
	render() {
		return html`
    ${mainStyle}
    <button class="menu-button" @click="${this.open}">
      <fm-icon>menu</fm-icon>
    </button>
    <div class="backdrop" @click="${this.close}"></div>
    <aside id="aside">
      <div class="top-container">
        <div class="logo-container">
          <img class="logo" src="${ROOT}/static/logo.svg" alt="Fundmarket logo" />
        </div>
        <h1 class="logo-text">Fundmarket</h1>
      </div>
      <nav>
      <router-link class="link">
          <a href="/accounts">Accounts</a>
        </router-link>
        <router-link class="link">
          <a href="/transactions">Transactions</a>
        </router-link>
        <router-link class="link">
          <a href="/tax">Tax</a>
        </router-link>
        <router-link class="link">
          <a href="/messages">Messages</a>
        </router-link>
        <router-link class="link">
          <a href="/orders">Orders</a>
        </router-link>
        <router-link class="link">
          <a href="/funds">Funds</a>
        </router-link>
        <router-link class="link">
          <a href="/aml">AML-functions</a>
        </router-link>
        <router-link class="link">
          <a href="/signatures">Signatures</a>
        </router-link>
        <router-link class="link">
          <a href="/reports">Reports</a>
        </router-link>
        <router-link class="link">
        <a href="/advisors">Advisors</a>
        </router-link>
        <router-link class="link">
          <a href="/formcalc">Form calculation</a>
        </router-link>
        <router-link class="link">
        <a href="/questions">Questions</a>
      </router-link>
      <router-link class="link">
            <a href="/shareholders">VP Ejerbog</a>
          </router-link>
      </nav>
    </aside>
    <main>
      <div id="toast-container"></div>
      <slot></slot>
    </main>
    `;
	}

	constructor() {
		super();
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
	}

	disconnectedCallback() {
		if (this.hasAttribute("open")) {
			document.body.style.overflow = "";
		}
	}

	open() {
		this.setAttribute("open", "");
		document.body.style.overflow = "hidden";
	}

	close() {
		this.removeAttribute("open");
		document.body.style.overflow = "";
	}
}

customElements.define("main-element", MainElement);
