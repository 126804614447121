import { LitElement, html, css } from "lit-element";
import { customElement, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import * as Sentry from "@sentry/browser";
import { get } from "../../api/client.js";
import { date } from "../../formatting/dateformats.js";
import { toCsv } from "../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"sh-position-list-view": ShPositionListView;
	}
}

type EtfPosition = {
	fund_name: string;
	subfund_name: string;
	isin_code: string;
	investor_name: string;
	custodian_name: string;
	custodynumber: string;
	custody_codes: string;
	investor_externalid: string;
	investor_name1: string;
	investor_name2: string;
	investor_name3: string;
	investor_name4: string;
	investor_name5: string;
	investor_zipcode: string;
	investor_city: string;
	investor_country: string;
	investor_clean_co: string;
	investor_address1: string;
	investor_address2: string;
	investor_clean_zipcode: string;
	investor_clean_country: string;
	investor_clean_cvr: string;
	position: number;
};

@customElement("sh-position-list-view")
export class ShPositionListView extends LitElement {
	@state()
	private etfPositions: EtfPosition[] = [];

	private sortedKey: keyof EtfPosition | null = null;

	@state()
	private asofdate: string = date(new Date());

	@state()
	private query: string = "";

	@state()
	private total_position: number = 0;

	@state()
	loading: boolean = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`:host {
			display: block;
			margin: 24px auto;
			max-width: 1400px;
		  }
		 th {
			cursor: pointer;
		 } 
		  `,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h1>Positions</h1>
        </div>
        <fm-form id = "parms" class="reportform no-print" @submit="${
					this.refresh
				}">
          <div class="form-space">
            <label>Asof date</label>
            <input type="date"  class="date-input" name="asofdate" value="${
							this.asofdate
						}" @change=${(e) => {
			this.asofdate = e.target.value;
		}}>
         </div>
          <div class="form-space">
            <label>Search</label>
            <input type="search" class="search-input" name="query" placeholder="Search" .value="${
							this.query
						}" @change=${(e) => {
			this.query = e.target.value;
		}}
						>
          </div>
          <div class="form-space">
            <fm-button-v2 id="submit_button" class="btn submit" @click="${
							this.refresh
						}" .loading="${this.loading}">Refresh</fm-button-v2>
          </div>
          <div class="form-space">
            <fm-button-v2 id="download_button" class="btn" @click="${
							this.download
						}">Download</fm-button-v2>
          </div>
        </fm-form>
        <table>
          <thead>
            <tr>
              <th @click="${() => this.sortBy("investor_name")}">Investor</th>
              <th @click="${() => this.sortBy("custodian_name")}">Custodian</th>
              <th @click="${() => this.sortBy("fund_name")}">Fund</th>
              <th @click="${() => this.sortBy("subfund_name")}">SubFund</th>
              <th @click="${() => this.sortBy("position")}">Position</th>
            </tr>
          </thead>
          <tbody>
            ${this.etfPositions.map(
							(etfTransaction) => html`
            <tr>
              <td>${etfTransaction.investor_name}</td>
              <td>${etfTransaction.custodian_name}</td>
              <td>${etfTransaction.fund_name}</td>
              <td>${etfTransaction.subfund_name}</td>
              <td class="numeric">${etfTransaction.position}</td>
            </tr>
            `,
						)}
          </tbody>
          <tfoot>
          <tr>
            <td colspan="5" style="text-align:right">Total</td>
            <td class="numeric">${this.total_position}</td>
          </tr>
          </tfoot>

        </table>
      </div>
    `;
	}

	async connectedCallback(): Promise<void> {
		super.connectedCallback();

		this.loading = true;
		await this.fetch({
			asofdate: this.asofdate,
			query: this.query,
		});
		this.loading = false;
	}

	async fetch(values: { asofdate: string; query: string }) {
		const result = await get<{ data: EtfPosition[] }>(
			`/etfs/positions?asof=${values.asofdate}&query=${values.query}`,
		);

		if (result.ok === false) {
			return;
		}

		this.etfPositions = result.value.data;

		this.total_position = this.etfPositions.reduce((a, e) => a + e.position, 0);
	}

	private async refresh() {
		this.loading = true;
		await this.fetch({
			asofdate: this.asofdate,
			query: this.query,
		});
		this.loading = false;
	}

	private sortBy(key: keyof EtfPosition): void {
		if (key === this.sortedKey) {
			this.etfPositions = this.etfPositions.slice(0).reverse();
			return;
		}

		this.sortedKey = key;
		this.etfPositions = this.etfPositions.slice(0).sort((a, b) => {
			const x = a[key];
			const y = b[key];
			if (typeof x === "string" && typeof y === "string") {
				return x.localeCompare(y);
			} else if (typeof x === "number" && typeof y === "number") {
				return x - y;
			} else {
				return 0;
			}
		});
	}

	private async download() {
		const csvHeaders = [
			"fund_name",
			"subfund_name",
			"isin_code",
			"investor_name",
			"custodian_name",
			"custodynumber",
			"custody_codes",
			"position",
			"investor_name1",
			"investor_name2",
			"investor_name3",
			"investor_name4",
			"investor_name5",
			"investor_zipcode",
			"investor_city",
			"investor_country",
			"investor_clean_co",
			"investor_address1",
			"investor_address2",
			"investor_clean_zipcode",
			"investor_clean_country",
			"investor_clean_cvr",
		];

		const csvFields = [
			"fund_name",
			"subfund_name",
			"isin_code",
			"investor_name",
			"custodian_name",
			"custodynumber",
			"custody_codes",
			"position",
			"investor_name1",
			"investor_name2",
			"investor_name3",
			"investor_name4",
			"investor_name5",
			"investor_zipcode",
			"investor_city",
			"investor_country",
			"investor_clean_co",
			"investor_address1",
			"investor_address2",
			"investor_clean_zipcode",
			"investor_clean_country",
			"investor_clean_cvr",
		];

		let txtdata = toCsv(csvHeaders, csvFields, this.etfPositions);
		try {
			txtdata = btoa(txtdata);
		} catch (err) {
			Sentry.captureException(err);
			// remove n@ascii
			txtdata = btoa(unescape(encodeURIComponent(txtdata)));
		}
		const a = document.createElement("a");
		a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
		a.setAttribute("download", "fm_positions.csv");
		a.click();
	}
}
