import { Router, RouterLink } from "runway";
import store from "../store/index.js";
import { BASE_PATH } from "../env.js";

import MainElement from "../main-element.js";
import NotFound from "../views/not-found/index.js";
import { SignInView } from "../views/sign-in/index.js";
import MailsModule from "../views/messages/routes.js";
import OrdersModule from "../views/orders/routes.js";
import FundsModule from "../views/funds/routes.js";
import AMLModule from "../views/aml/routes.js";
import SignModule from "../views/signatures/routes.js";
import AccountsModule from "../views/accounts/routes.js";
import TransactionsModule from "../views/transactions/routes.js";
import TaxModule from "../views/tax/routes.js";
import ReportsModule from "../views/reports/routes.js";
import AdvisorsModule from "../views/advisors/routes.js";
import FormcalcModule from "../views/formcalc/routes.js";
import QuestionsModule from "../views/questions/routes.js";
import ShareholdersModule from "../views/shareholders/routes.js";

const router = new Router({
	root: BASE_PATH,
	routes: [
		{
			path: "",
			redirect: "/login",
		},
		{
			path: "login",
			component: SignInView,
			properties: (route) => ({
				redirect: route.query.get("redirect"),
			}),
		},
		{
			path: "**",
			guard: () => !store.getState().status.authenticated,
			redirect: "/login",
		},
		{
			path: "",
			component: MainElement,
			children: [
				MailsModule,
				OrdersModule,
				FundsModule,
				AMLModule,
				AccountsModule,
				TransactionsModule,
				TaxModule,
				ReportsModule,
				AdvisorsModule,
				FormcalcModule,
				QuestionsModule,
				SignModule,
				ShareholdersModule,
				{
					path: "404",
					component: NotFound,
				},
				{
					path: "**",
					redirect: "/404",
				},
			],
		},
	],
});

RouterLink.use(router);

export default router;
