import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { money } from "../../formatting/numberformats.js";
import { sort } from "../../utils.js";

export default class TaxFormView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			rerender: {
				type: Number,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
       <div class="card">
        <div class="card-header">
          <h1>Selvangivelser</h1>
        </div>
        <div class="card-block">
          <input type="search" class="search-input" id="query" placeholder="Search" @change="${
						this.onInput
					}">
          <fm-button class="btn" @click="${this.refresh}" >Refresh</fm-button>
        </div>
        <table>
          <thead>
            <tr>
              <th data-key="custody_code" @click="${this.sort}">Custody </th>
              <th data-key="custody_owner" @click="${this.sort}">Owner</th>
              <th data-key="year" @click="${this.sort}">Year</th>
              <th data-key="owner_count" @click="${this.sort}">Owner Count</th>
              <th data-key="cpr" @click="${this.sort}">CPR</th>
              <th data-key="taxfield_code" @click="${this.sort}">Tax field</th>
              <th data-key="security_name" @click="${this.sort}">Security</th>
              <th class="numeric" data-key="amount" @click="${this.sort}">Amount</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td>${row.custody_code}</td>
                <td>${row.custody_owner}</td>
                <td>${row.year}</td>
                <td>${row.owner_count}</td>
                <td>${row.cpr}</td>
                <td>${row.taxfield_code}
                <br>${row.taxfield_description}</td>
                <td>${row.security_name}</td>
                <td class="numeric">${money(row.amount)}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
   `;
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.onInput = this.onInput.bind(this);
		this.fetch = this.fetch.bind(this);
		this.refresh = this.refresh.bind(this);
		this.sort = this.sort.bind(this);
		this.rerender = 0;
	}

	onInput() {
		this.query = this.shadowRoot.querySelector("#query").value;
	}

	async fetch() {
		this.rows = await api.get(`/taxform?q=${this.query}`);
	}

	async refresh(event) {
		const target = event.target;
		target.loading = true;
		await this.fetch();
		target.loading = false;
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
		this.rerender = this.rerender + 1;
	}
}

customElements.define("taxform-view", TaxFormView);
