import TaxView from "./tax-view.js";
import TaxFormView from "./tax-form-view.js";
import { TaxFilingsView } from "./tax-filings-view.js";
import { TaxFilingView } from "./tax-filing-view.js";
import type { RouteOptions } from "runway";

export default {
	path: "tax",
	component: TaxView,
	children: [
		{
			path: "",
			component: TaxFormView,
		},
		{
			path: "tax-filings",
			component: TaxFilingsView,
		},
		{
			path: "tax-filings/:filing_id",
			component: TaxFilingView,
			properties: ({ parameters }) => ({
				filing_id: parameters.get("filing_id"),
			}),
		},
	],
} satisfies RouteOptions;
